import React, { useState, useEffect, useMemo } from 'react';
import QRCode from 'react-qr-code';
import styles from "./QR.less";

type Props = {
    link: string | undefined;
};

const QR: React.FC<Props> = (props: Props) => {

    const has = !!props.link;

    const remote_auth = useMemo(() => {
        const remote_auth_str = localStorage.getItem("remote_auth");
        return remote_auth_str !== null;
    }, []);

    const [auth, setAuth] = useState(remote_auth);

    const toggleAuth = () => {
        const new_auth = !auth;
        setAuth(new_auth);
        if (new_auth) {
            localStorage.setItem("remote_auth", "true");
        } else {
            localStorage.removeItem("remote_auth");
        }
    };

    return (
        <div className={styles.QR + (has ? " active" : "")}>
            <div className={styles.QRWrapper + (auth ? " auth" : "")}>
                {has ? (
                    <QRCode
                        className={styles.QRCode}
                        onClick={toggleAuth}
                        value={props.link + (auth ? "&p=1" : "")}
                        bgColor="#ffffff"
                        fgColor="#0a0a0c"
                        size={96}
                    />
                ) : (
                    <div className={styles.QRMock} />
                )}
            </div>
            <div className={styles.Info}>
                Scan to switch between available modes!
            </div>
        </div>
    );
};

export default QR;

export {
    QR,
    Props,
};
