import React, { useState, useEffect, useRef } from 'react';
import styles from "./Background.less";
import color from "color";
import { set } from 'date-fns';

type TrafficLight = {
    kind: "traffic-light";
    blink?: boolean;
    color: string;
};

type Regular = {
    kind: "regular";
};

type Props = TrafficLight | Regular;

const Background = (props: Props) => {
    const [color_str, setColor] = useState("black");
    const borderRef = useRef<HTMLDivElement>(null);

    const blink = () => {
        requestAnimationFrame(() => {
            borderRef.current?.classList.remove(styles.show);
            requestAnimationFrame(() => {
                borderRef.current?.classList.add(styles.show);
            });
        });
    };

    // @ts-ignore
    window.blink = blink;

    useEffect(() => {
        if (props.kind === "traffic-light")
        {
            setColor(props.color);

            if (props.blink)
            {
                blink();
            }
        }
        else
        {
            setColor("black");
        }

    }, [props]);

    // Traffic light
    return (
        <div className={styles.Background}>
            <div
                className={styles.TrafficLight}
                style={{
                    opacity: color_str !== "black"
                        ? "0.618"
                        : "0"
                }}
            >
                <div
                    className={styles.Box_TopLeft}
                    style={{
                        background: color_str,
                        boxShadow: "0 0 calc(10 * 24px) calc(14 * 24px) " + color_str,
                    }}
                ></div>
                <div className={styles.Box_TopRight}></div>
                <div className={styles.Box_BottomLeft}></div>
                <div className={styles.Box_BottomRight}></div>
            </div>
            <div ref={borderRef} className={styles.Border}></div>
        </div>
    );
};

export default Background;

export {
    Background,
    Props,
};
