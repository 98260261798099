
const MIN = 0;
const MAX = 1;

const colors = [
    "85ffb6", // Green
    "ffe456", // Yellow
    "ff3d3d", // Red
];

const constrainGen =
    (low: number, high: number) =>
    (n: number) =>
    Math.max(Math.min(high, n), low);

const mapGen =
    (low1: number, high1: number) =>
    (low2: number, high2: number) =>
    (n: number) =>
    {
        const f = (high2 - low2) / (high1 - low1);
        return low2 + (n - low1) * f;
    };

const weighted = (a: string, b: string, w: number) => {

    let res = "";

    for (let i = 0; i < 6; i += 2)
    {
        const _a = parseInt(a.slice(i, i + 2), 16);
        const _b = parseInt(b.slice(i, i + 2), 16);

        const diff = _b - _a;
        const adj = _a + diff * w;

        res += Math.round(adj).toString(16);
    }

    return "#" + res;
};

const constrain = constrainGen(MIN, MAX);
const map = mapGen(MIN, MAX)(0, colors.length - 1);

const trafficLightColor = (velocity: number) => {

    const constrained = constrain(velocity);

    const mapped = map(constrained);

    return weighted(
        colors[Math.floor(mapped)],
        colors[Math.ceil(mapped)],
        mapped - Math.floor(mapped)
    );
};

export default trafficLightColor;
