import { useState, useRef, useEffect } from "react";

function useSeconds() {
    const [seconds, setSeconds] = useState<number>(0);
    const start = useRef(performance.now());

    useEffect(() => {
        const diff = (start.current + (seconds * 1000)) - performance.now();

        const timer = setTimeout(() => {
            setSeconds(ps => ps + 1);
        }, 1000 + diff);

        return () => clearTimeout(timer);
    }, [seconds]);

    return seconds;
}

export default useSeconds;

export {
    useSeconds,
};
