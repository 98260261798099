import React, { useState, useEffect } from 'react';
import { Squat } from '@alphatekas/pwr_core/dist/squatter';
import styles from "./MidwayReport.less";

type Props = {
    squat: Squat | undefined;
    lastSquat: Squat | undefined;
    drop: number;
    rep: number;
    color: string;
    working: string;
};

const MidwayReport = (props: Props) => {

    const reps = props.rep;

    let velocity = 0;
    let power = 0;

    if (props.squat !== undefined)
    {
        velocity = props.squat.velocity.mean_propulsive;
        power = props.squat.power.peak_propulsive;
    }

    let distance = "0 cm"
    if (props.squat !== undefined)
    {
        distance = Math.round(props.squat.displacement * 100) + " cm";
    }

    return (
        <div className={styles.MidwayReport}>
            <div className={styles.Top}>
                <div>
                    <div className={styles.Title}>
                        Currently working
                    </div>
                    <div className={styles.Value}>
                        {props.working}
                    </div>
                </div>
                <div>
                    <div className={styles.Title}>
                        Repetitions
                    </div>
                    <div className={styles.Value}>
                        {props.rep}
                    </div>
                </div>
                <div>
                    <div className={styles.Title}>
                        Velocity
                    </div>
                    <div className={styles.Value}>
                        {velocity.toFixed(2)} m/s
                    </div>
                </div>
                <div>
                    <div className={styles.Title}>
                        Power
                    </div>
                    <div className={styles.Value}>
                        {power.toFixed()} W
                    </div>
                </div>
                <div>
                    <div className={styles.Title}>
                        Distance
                    </div>
                    <div className={styles.Value}>
                        {distance}
                    </div>
                </div>
            </div>
            <div className={styles.Hr} />
            <div className={styles.Bottom}>
                <VelocityView color={props.color} v={velocity} />
            </div>
        </div>
    );
};

type VelocityViewProps = {
    v: number;
    color: string;
};

const VelocityView = (props: VelocityViewProps) => {

    const v = props.v === 0
        ? 0.7
        : props.v;

    const MIN = 0;
    const MAX = 1.5;

    const velocityStop = v / MAX;

    return (
        <div className={styles.VelocityView}>
            {props.v !== 0 &&
                <div
                    className={styles.Ticker}
                    style={{
                        left: (props.v / MAX) * (1080 - (24 * 8)) + "px"
                    }}
                >
                    <div>{props.v.toFixed(2)}</div>
                </div>
            }
            <svg className={styles.VelocityBars}>
                <path
                    d="M0 40L1082 40"
                    stroke="url(#paint0_linear)"
                    strokeWidth="80"
                    strokeDasharray="2 4"
                />
                <defs>
                    <linearGradient
                        id="paint0_linear"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#5A5A5C"/>
                        <stop
                            offset={(velocityStop - 0.15).toFixed(4)}
                            stopColor="#C5C5C7"
                        />
                        {props.v !== 0 &&
                            <stop
                                offset={velocityStop.toFixed(4)}
                                stopColor={props.color}
                            />
                        }
                        <stop
                            offset={(velocityStop + 0.15).toFixed(4)}
                            stopColor="#C5C5C7"
                        />
                        <stop offset="1" stopColor="#5A5A5C"/>
                    </linearGradient>
                </defs>
            </svg>
            <svg
                className={styles.VelocityLine}
            >
                <line
                    x1="-3.49691e-07"
                    y1="4"
                    x2="1082"
                    y2="3.99992"
                    stroke="white"
                    strokeWidth="8"
                    strokeDasharray="1 8"
                />
            </svg>
            <div className={styles.VelocityMarkers}>
                <div>0.0</div>
                <div>0.5</div>
                <div>1.0</div>
                <div>1.5</div>
            </div>
            <div className={styles.VelocityKinds}>
                <div>Strength</div>
                <div>Power</div>
                <div>Speed</div>
            </div>
        </div>
    );
};

export default MidwayReport;

export {
    MidwayReport,
    Props,
};
