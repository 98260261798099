import React, { useState, useEffect, useContext } from 'react';
import styles from "./Connect.less";
import * as AT from "../../../init";
import getId from '@alphatekas/pwr_core/dist/helpers/getId';
import ScreenContext, { Screen } from "../../../helpers/ScreenContext";

type Props = {

};

const Connect = (props: Props) => {
    const [screen, setScreen] = useContext(ScreenContext);
    const [code, setCode] = useState("");

    const poll = () => {
        const code = generate_code();
        setCode(code);

        fetch(`https://banshee.alphatek.no/get/${code}`, { mode: "cors" })
            .then(res => {
                if (res.ok) {
                    return res.json();
                }

                throw new Error("Oh noes!");
            })
            .then(res => {
                localStorage.
                    setItem(
                        "alphapwr_id", JSON.stringify({
                            date: Date.now(),
                            value: res,
                        })
                    );

                AT.forceplate.init(res);
                gtag("event", "connected", { id: res });
                setScreen(Screen.WeighIn);
            })
            .catch(() => {
                setTimeout(poll, 1000);
            });
    };

    useEffect(() => {
        const id = getId();

        if (id !== null) {
            AT.forceplate.init(id);
            gtag("event", "connected", { id });
            setScreen(Screen.WeighIn);
        }
        else {
            gtag("event", "connecting", { code });
            poll();
        }
    }, []);

    return (
        <div className={styles.Connect}>
            {code !== "" && (
                <div className={styles.Code}>
                    {code}
                </div>
            )}
        </div>
    );
};

const allowed = "abcdefghijklmnpqrstuvwxyz123456789";

function generate_code()
{
    const arr = [];

    for (let i = 0; i < 4; ++i)
    {
        const idx = Math.floor(Math.random() * allowed.length);
        arr.push(allowed[idx]);
    }

    return arr.join("").toUpperCase();
}

export default Connect;

export {
    Connect,
    Props,
};
