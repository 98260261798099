import React, { useState, useEffect, useContext, useMemo } from 'react';
import styles from "./WeighIn.less";
import WithProgress from '../../ux/WithProgress';
import moveSvg from "../../ux/svg/icons/large/Move.svg";
import weightSvg from "../../ux/svg/icons/large/Pick up Weights.svg";
import * as AT from "../../../init";
import ScreenContext, { Screen } from "../../../helpers/ScreenContext";
import match from '@korkje/match';
import Countdown from '../../ux/Countdown';
import QR from './QR';
import { s_to_mss } from '../../../helpers/timeFormat';
import plausible from '../../../plausible';
import getId from '@alphatekas/pwr_core/dist/helpers/getId';

type Props = {
    link: string;
};

enum State {                    // (level, active)
    _0_Nothing,                 // (0, false)
    _1_LevellingPersonWeight,   // (0, true)
    _2_PersonWeight,            // (1, true)
}

const WeighIn = (props: Props) => {
    const [state, setState] = useState(State._0_Nothing);
    const [screen, setScreen] = useContext(ScreenContext);

    const onLevel = (level: number | undefined) => {
        setState(ps => {
            if (level === undefined) {
                return State._0_Nothing;
            }

            gtag("event", "weighed_in", { level });
            plausible.trackEvent("weigh_in", { props: {
                fp_id: getId(),
            }});

            return State._2_PersonWeight;
        });
    };

    const onActive = (active: boolean) => {
        console.log("onActive");
        setState(ps => {
            if (active) {
                gtag("event", "weighing_in");
                return State._1_LevellingPersonWeight;
            }
            return State._0_Nothing;
        });
    };

    useEffect(() => {
        if (AT.forceplate.last_active) {
            gtag("event", "weighing_in");
            setState(State._1_LevellingPersonWeight);
        }

        AT.leveller.on("level", onLevel);
        AT.forceplate.on("active", onActive);

        return () => {
            AT.leveller.off("level", onLevel);
            AT.forceplate.off("active", onActive);
        };
    }, []);

    useEffect(() => {
        if (state === State._2_PersonWeight) {
            const t = setTimeout(
                () => setScreen(Screen.JustLift),
                500
            );

            return () => clearTimeout(t);
        }
    }, [state]);

    return (
        <div className={styles.WeighIn}>
            <div>
                <div className={styles.TitleAndQR}>
                    <div className={styles.AppName}>
                        Squat
                    </div>
                    <QR link={props.link} />
                    <Settings />
                </div>
                <Instruction
                    active={state === State._0_Nothing}
                    step={1}
                    text={{
                        header: "Pick up weights!",
                        description: "Go pick up your weights and step onto AlphaPWR",
                        // iconText: "We'll wait while you pick up your weights"
                    }}
                    svg={weightSvg}
                />
                <Instruction
                    active={state !== State._0_Nothing}
                    step={2}
                    text={{
                        header: "Calibrating...",
                        description: "Try standing as still as you can while we calibrate!",
                        // iconText: "Almost there, keep standing still!"
                    }}
                    svg={moveSvg}
                    progress={
                        match(state)
                            .on(State._0_Nothing, "empty")
                            .on(State._1_LevellingPersonWeight, "running")
                            .on(State._2_PersonWeight, "full")
                            .result()
                    }
                />
            </div>
        </div>
    );
};

const Settings = (props: {}) => {

    const stop_percentage = useMemo(() => {
        const percentage_str = localStorage.getItem("squat_stop_percentage");
        if (percentage_str !== null) {
            return (+percentage_str);
        }
        return null;
    }, []);

    const results_timeout = useMemo(() => {
        const results_timeout_str = localStorage.getItem("squat_results_timeout");
        if (results_timeout_str !== null) {
            return (+results_timeout_str);
        }
        return null;
    }, []);

    if (stop_percentage === null && results_timeout === null) {
        return null;
    }

    return (
        <div className={styles.Settings}>
            <div className={styles.SettingsHeader}>
                Settings
                <span> - change by scanning QR</span>
            </div>
            {stop_percentage && <Setting name="Stop percentage" value={`${stop_percentage}%`}/>}
            {results_timeout && <Setting name="Results timeout" value={`${s_to_mss(results_timeout)}`}/>}
        </div>
    );
};

type SettingProps = {
    name: string;
    value: string;
};

const Setting = (props: SettingProps) => (
    <div className={styles.Setting}>
        <span className={styles.SettingName}>{props.name}</span>
        <span> is set to </span>
        <span className={styles.SettingValue}>{props.value}</span>
    </div>
);

type InstructionProps = {
    active: boolean;
    step: number;
    text: {
        header: string;
        description: string;
        // iconText: string;
    }
    svg: string;
    progress?: "empty" | "running" | "full";
};

const Instruction = (props: InstructionProps) => {
    const [progress, setProgress] = useState(0);

    const onProgress = (progress: number) => {
        setProgress(progress);
    };

    useEffect(() => {
        if (props.progress === "empty") {
            setProgress(0);
        }
        else if (props.progress === "running") {
            AT.leveller.on("progress", onProgress);
            return () => AT.leveller.off("progress", onProgress);
        }
        else if (props.progress === "full") {
            setProgress(1);
        }
    }, [props.progress]);

    return (
        <div className={styles.Instruction + (props.active ? " active" : "")}>
            <div className={styles.Right}>
                {props.progress !== undefined ? (
                    <WithProgress
                        className={styles.Icon}
                        progress={progress}
                    >
                        <img src={props.svg} />
                    </WithProgress>
                ) : (
                    <div className={styles.Icon}>
                        <img src={props.svg} />
                    </div>
                )}
                {/* <div className={styles.IconText}>
                    {props.text.iconText}
                </div> */}
            </div>
            <div className={styles.Left}>
                <div className={styles.Step}>
                    Step {props.step} of 2
                </div>
                <div className={styles.Header}>
                    {props.text.header}
                </div>
                <div className={styles.Description}>
                    {props.text.description}
                </div>
            </div>
        </div>
    );
};

export default WeighIn;

export {
    WeighIn,
    Props,
};
