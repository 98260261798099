
type ReportTexts = {
    main: string;
    expanded: string;
}

const getReportTexts = (kind: string): ReportTexts => {
    if (kind === "Strength")
    {
        return {
            main: "All done! Based on your stats, you've been working on your strength. Check out details below.",
            expanded: "Lifting heavier weights at a lower velocity improves your overall strength. Improving strength will help you lift heavier and endure more."
        };
    }
    else if (kind === "Power")
    {
        return {
            main: "All done! Based on your stats, you've been working on your power. Check out details below.",
            expanded: "Lifting weights at a medium velocity improves your overall power. Improving power is the ultimate compromise between strength and speed!"
        };
    }
    else if (kind === "Speed")
    {
        return {
            main: "All done! Based on your stats, you've been working on your speed. Check out details below.",
            expanded: "Lifting lighter weights at a higher velocity improves your overall power. Improving speed will help you lift faster, run faster, jump higher, etc."
        };
    }

    return {
        main: "Something went wrong!",
        expanded: "Something went wrong!"
    };
};

export {
    getReportTexts,
};
