import React, { useState, useEffect, useContext } from 'react';
import trafficLightColor from '../../../helpers/trafficLightColor';
import * as AT from "../../../init";
import { Squat } from '@alphatekas/pwr_core/dist/squatter';
import styles from "./JustLift.less";
import LiveView from './LiveView';
import Results from './Results';
import ScreenContext, { Screen } from "../../../helpers/ScreenContext";
import plausible from '../../../plausible';
import getId from '@alphatekas/pwr_core/dist/helpers/getId';

type Props = {
    trafficLight: (color: string | undefined, blink?: boolean) => void;
};

declare var window: Window & {
    addSquat: Function;
    done: Function;
};

const JustLift = (props: Props) => {
    const [screen, setScreen] = useContext(ScreenContext);

    // Initialize traffic light
    useEffect(() => {

        // Reset bg on unmount
        return () => props.trafficLight(undefined);
    }, []);

    const [squats, setSquats] = useState<Squat[]>([]);

    const addSquat = (squat: Squat) => {
        gtag("event", "squat", {
            velocity_mpv: squat.velocity.mean_propulsive,
            velocity_ppv: squat.velocity.peak_propulsive,
            velocity_mcv: squat.velocity.mean_concentric,
            power_mpp: squat.power.mean_propulsive,
            power_ppp: squat.power.peak_propulsive,
            displacement: squat.displacement,
        });

        plausible.trackEvent("squat_rep", { props: {
            fp_id: getId(),
        }});

        setSquats([
            ...squats,
            squat,
        ]);

        // const color = trafficLightColor(squat.mpv);

        // props.trafficLight(color);
    };

    // Listen for squats
    useEffect(() => {
        AT.squatter.on("squat", addSquat);

        return () => AT.squatter.off("squat", addSquat);
    });

    const [done, setDone] = useState<boolean>(false);

    // If levels change at this point, user is done
    useEffect(() => {
        AT.leveller.once("level", () => {
            // TODO: If no squats, send straight to "weigh-in"

            setDone(true);
            props.trafficLight(trafficLightColor(0));
        });
    }, []);

    window.addSquat = () => {
        const v = 0.6 + Math.random() * 0.8;
        const n = squats.length;
        addSquat({
            velocity: {
                mean_propulsive: v * 0.7,
                peak_propulsive: v,
                mean_concentric: v + Math.random() + Math.random() * 0.1
            },
            power: {
                mean_propulsive: 127,
                peak_propulsive: 203
            },
            time: 10,
            displacement: 1,
            timing: [n * 2000, n * 2000 + 500, n * 2000 + 1000, n * 2000 + 1500],
        });
    };

    window.done = () => {
        setDone(true);
    };

    // // For testing reports
    // useEffect(() => {
    //     setDone(true);
    //     setTimeout(() => {
    //         props.trafficLight(trafficLightColor(0));
    //     }, 500)

    //     const v = 0.5;

    //     setSquats([{
    //         velocity: {
    //             mean_propulsive: 0.95,
    //             peak_propulsive: v,
    //             mean_concentric: v + Math.random() + Math.random() * 0.1
    //         },
    //         power: {
    //             mean_propulsive: 127,
    //             peak_propulsive: 203
    //         },
    //         time: 10,
    //         displacement: 0.51,
    //         timing: [0 * 2000, 0 * 2000 + 500, 0 * 2000 + 1000, 0 * 2000 + 1500],
    //     }, {
    //         velocity: {
    //             mean_propulsive: 0.91,
    //             peak_propulsive: v,
    //             mean_concentric: v + Math.random() + Math.random() * 0.1
    //         },
    //         power: {
    //             mean_propulsive: 127,
    //             peak_propulsive: 203
    //         },
    //         time: 10,
    //         displacement: 0.5,
    //         timing: [1 * 2000, 1 * 2000 + 500, 1 * 2000 + 1000, 1 * 2000 + 1500],
    //     }, {
    //         velocity: {
    //             mean_propulsive: 0.93,
    //             peak_propulsive: v,
    //             mean_concentric: v + Math.random() + Math.random() * 0.1
    //         },
    //         power: {
    //             mean_propulsive: 127,
    //             peak_propulsive: 203
    //         },
    //         time: 10,
    //         displacement: 0.51,
    //         timing: [2 * 2000, 2 * 2000 + 500, 2 * 2000 + 1000, 2 * 2000 + 1500],
    //     }, {
    //         velocity: {
    //             mean_propulsive: 0.86,
    //             peak_propulsive: v,
    //             mean_concentric: v + Math.random() + Math.random() * 0.1
    //         },
    //         power: {
    //             mean_propulsive: 127,
    //             peak_propulsive: 203
    //         },
    //         time: 10,
    //         displacement: 0.49,
    //         timing: [3 * 2000, 3 * 2000 + 500, 3 * 2000 + 1000, 3 * 2000 + 1500],
    //     }, {
    //         velocity: {
    //             mean_propulsive: 0.81,
    //             peak_propulsive: v,
    //             mean_concentric: v + Math.random() + Math.random() * 0.1
    //         },
    //         power: {
    //             mean_propulsive: 127,
    //             peak_propulsive: 203
    //         },
    //         time: 10,
    //         displacement: 0.48,
    //         timing: [4 * 2000, 4 * 2000 + 500, 4 * 2000 + 1000, 4 * 2000 + 1500],
    //     }, {
    //         velocity: {
    //             mean_propulsive: 0.76,
    //             peak_propulsive: v,
    //             mean_concentric: v + Math.random() + Math.random() * 0.1
    //         },
    //         power: {
    //             mean_propulsive: 127,
    //             peak_propulsive: 203
    //         },
    //         time: 10,
    //         displacement: 0.46,
    //         timing: [5 * 2000, 5 * 2000 + 500, 5 * 2000 + 1000, 5 * 2000 + 1500],
    //     }]);
    // }, []);

    const currentSquat = squats[squats.length - 1];
    const lastSquat = squats[squats.length - 2];
    const reps = squats.length;
    const max = Math.max(...squats.map(_ => _.velocity.mean_propulsive))
    const drop = reps > 0
        ? 1 - currentSquat.velocity.mean_propulsive / max
        : 0;

    let low = 0;
    let high = 1;

    let working = "N/A";

    if (max > 0 && max < 0.55) {
        // Strength
        low = 0.15;
        high = 0.3;
        working = "Strength"
    }
    else if (max >= 0.55) {
        // Power or Speed
        low = 0.05;
        high = 0.2
        if (max < 0.95) {
            working = "Power";
        }
        else {
            working = "Speed";
        }
    }

    // Custom cutoff!
    {
        const percentage_str = localStorage.getItem("squat_stop_percentage");

        if (percentage_str !== null) {
            const percentage = (+percentage_str) / 100;
            low = percentage / 3;
            high = percentage;
        }
    }

    const constrained = constrain(low, high, drop);
    const mapped = map(low, high, 0, 1, constrained);
    const color = trafficLightColor(mapped);

    useEffect(() => {
        if (squats.length > 0) {
            props.trafficLight(color, true);
        }
        else {
            props.trafficLight(color);
        }
    }, [squats.length]);

    const dropPercentage = Math.min(99, Math.round(drop * 100));

    let motivation = "Start lifting!"

    if (currentSquat !== undefined) {
        if (mapped < 0.33) {
            motivation = "Keep going!";
        }
        else if (mapped < 0.66) {
            motivation = "You can do it!";
        }
        else if (mapped < 1) {
            motivation = "Push yourself!";
        }
        else {
            motivation = "That's enough!";
        }
    }

    return (
        <div className={styles.JustLift}>
            {!done && (
                <LiveView
                    squat={currentSquat}
                    lastSquat={lastSquat}
                    color={color}
                    rep={reps}
                    drop={dropPercentage}
                    working={working}
                    motivation={motivation}
                />
            ) || (
                <Results
                    squats={squats}
                    working={working}
                />
            )}
        </div>
    );
};

const constrain = (low: number, high: number, n: number) =>
    Math.max(Math.min(high, n), low);

const map =  (low1: number, high1: number, low2: number, high2: number, n: number) => {
    const f = (high2 - low2) / (high1 - low1);
    return low2 + (n - low1) * f;
};
export default JustLift;

export {
    JustLift,
    Props,
};
