import { useState, useRef, useEffect } from "react";

function useSecondsDeferred() {
    const [seconds, setSeconds] = useState<number>(0);
    const [started, setStarted] = useState<boolean>(false);
    const start = useRef<number>();

    useEffect(() => {
        if (!started) {
            return;
        }

        const diff = (start.current + (seconds * 1000)) - performance.now();

        const timer = setTimeout(() => {
            setSeconds(ps => ps + 1);
        }, 1000 + diff);

        return () => clearTimeout(timer);
    }, [seconds, started]);

    const startTimer = () => {
        if (!started) {
            start.current = performance.now();
            setSeconds(0);
            setStarted(true);
        }
    };

    const stopTimer = () => {
        setStarted(false);
    }

    return [seconds, startTimer, stopTimer] as const;
}

export default useSecondsDeferred;

export {
    useSecondsDeferred,
};
