
type Tempo = number[];

function tempos_from_timings(timings: number[][], hz: number): Tempo[] {
    const tempos: Tempo[] = [];

    for (let i = 0; i < timings.length; ++i) {
        const current = timings[i];
        const next_start = timings[i + 1]?.[0];

        tempos.push([
            (current[1] - current[0]) / hz,
            (current[2] - current[1]) / hz,
            (current[3] - current[2]) / hz,
            next_start !== undefined
                ? (next_start - current[3]) / hz
                : undefined,
        ]);
    }

    return tempos;
}

export {
    tempos_from_timings,
};
