import React, { useState, useEffect, useMemo, useRef, useContext, useCallback } from 'react';
import * as AT from "../../../init";
import { Squat } from '@alphatekas/pwr_core/dist/squatter';
import styles from "./Results.less";
import tapSvg from "../../ux/svg/icons/large/Tap.svg";
import velocitySvg from "../../ux/svg/icons/small/Data.svg";
import distanceSvg from "../../ux/svg/icons/small/Graph.svg";
import { getReportTexts } from '../../../helpers/text/report';
import generateInterval from '../../../helpers/generateInterval';
import { tempos_from_timings } from '../../../helpers/tempo';
import useSeconds from '../../../helpers/useSeconds';
import ScreenContext, { Screen } from '../../../helpers/ScreenContext';
import clamp from '../../../helpers/clamp';
import { s_to_mss } from "../../../helpers/timeFormat";
import plausible from '../../../plausible';
import getId from '@alphatekas/pwr_core/dist/helpers/getId';

type Props = {
    squats: Squat[];
    working: string;
};

const getTimeout = () => {
    const ls_timeout = localStorage.getItem("squat_results_timeout");

    if (ls_timeout !== null) {
        return +ls_timeout;
    }

    return 2 * 60;
}

const Results = (props: Props) => {

    const timeout = useMemo(getTimeout, []);

    const [screen, setScreen] = useContext(ScreenContext);

    const rnd = useRef(Math.random());

    const seconds = useSeconds();

    useEffect(() => {
        if (seconds >= timeout) {
            setScreen(Screen.WeighIn);
            // AT.auth.signOut();
        }
    }, [seconds]);

    const onActive = (active: boolean) => {
        if (active) {
            setScreen(Screen.WeighIn);
        }
    };

    useEffect(() => {
        gtag("event", "squat_results", {
            num: props.squats.length,
        });
        plausible.trackEvent("squat_set", { props: {
            fp_id: getId(),
        }});
        AT.forceplate.on("active", onActive);
        return () => AT.forceplate.off("active", onActive);
    }, []);

    const [avgV, avgD] = useMemo(
        () => [
            props.squats
                .map(_ => _.velocity.mean_propulsive)
                .reduce((a, b) => a + b, 0)
                / props.squats.length,
            props.squats
                .map(_ => _.displacement)
                .reduce((a, b) => a + b, 0)
                / props.squats.length,
        ],
        [rnd.current, props.squats.length],
    );

    const texts = useMemo(
        () => getReportTexts(props.working),
        [props.working],
    );

    return (
        <div className={styles.Results}>
            <div className={styles.Tap}>
                <div>
                    <img src={tapSvg} />
                </div>
                <div>
                    Tap to go back
                </div>
            </div>
            <div className={styles.Top}>
                <div className={styles.TopTop}>
                    <div className={styles.Left}>
                        {texts.main}
                    </div>
                    <div className={styles.Right}>
                        {texts.expanded}
                    </div>
                </div>
                <div className={styles.TopBottom}>
                    <div className={styles.Thing}>
                        <div>
                            Free lifting
                        </div>
                        <div>
                            Squats
                        </div>
                    </div>
                    <div className={styles.Thing}>
                        <div>
                            Repetitions
                        </div>
                        <div>
                            {props.squats.length}
                        </div>
                    </div>
                    <div className={styles.Thing}>
                        <div>
                            Avg. velocity (MPV)
                        </div>
                        <div>
                            {avgV.toFixed(2)} m/s
                        </div>
                    </div>
                    <div className={styles.Thing}>
                        <div>
                            Avg. distance
                        </div>
                        <div>
                            {(avgD * 100).toFixed()} cm
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles.Bottom}>
                <WithDescription
                    icon={velocitySvg}
                    header="Velocity (m/s) per repetition"
                    description="Mean velocity of the propulsive phase"
                >
                    <VelocityGraph
                        velocities={props.squats.map(_ => _.velocity.mean_propulsive)}
                    />
                </WithDescription>
                <WithDescription
                    icon={distanceSvg}
                    header="Distance (cm) per repetition"
                    description="Absolute displacement of center of mass"
                >
                    <DistanceGraph
                        distances={props.squats.map(_ => _.displacement)}
                    />
                </WithDescription>
                <WithDescription
                    icon={distanceSvg}
                    header="Details"
                    description="Data per repetition and averages"
                    footnotes={[{
                        num: 1,
                        text: "Relative change in MPV from previous rep",
                    }, {
                        num: 2,
                        text: "Time spent (10ths of seconds) in the phases, i.e. down, pause, up, pause",
                    }]}
                >
                    <Details squats={props.squats} />
                </WithDescription>

            </div>
            <Counter seconds={seconds} timeout={timeout} />
        </div>
    );
};

type CounterProps = {
    seconds: number;
    timeout: number;
}

const Counter = (props: CounterProps) => {
    const seconds = Math.min(props.seconds, props.timeout);

    let percentage = clamp(0, 100, (seconds / props.timeout) * 100);

    return (
        <div className={styles.Counter}>
            <div className={styles.CounterText}>
                <span>{s_to_mss(seconds)}</span><span>{s_to_mss(props.timeout)}</span>
            </div>
            <div
                className={styles.CounterBar}
                style={{
                    width: `${percentage}%`,
                }}
            />
        </div>
    );
}

type WithDescriptionProps = {
    icon: string;
    header: string;
    description: string;
    footnotes?: {
        num: number;
        text: string;
    }[];
    children?: JSX.Element;
};

function WithDescription(props: WithDescriptionProps)
{
    return (
        <div className={styles.WithDescription}>
            <div className={styles.DescriptionView}>
                <img src={props.icon} />
                <div>
                    <div>{props.header}</div>
                    <div>{props.description}</div>
                    {props.footnotes?.map((_, i) => (
                        <div key={i} className={styles.Footnote}>
                            <sup>{_.num}</sup>
                            <div>{_.text}</div>
                        </div>
                    ))}
                </div>
            </div>
            <div>
                {props.children}
            </div>
        </div>
    );
}

const GRAPH_MIN_REPS = 8;

type VelocityGraphProps = {
    velocities: number[];
};

const VelocityGraph = (props: VelocityGraphProps) => {

    const numHorizontal = 4;
    const numVertical = Math.max(GRAPH_MIN_REPS, props.velocities.length);

    const max = Math.max(...props.velocities);
    const min = Math.min(...props.velocities);

    const interval = generateInterval(min, max, 0.3, 3);

    return (
        <div className={styles.VelocityGraph}>
            <div className={styles.VGraphView}>
                {/* Vertical lines */}
                {[...Array(numVertical)].map((e, i) =>
                    <div
                        key={Math.random()}
                        className={styles.VVerticalLine}
                        style={{
                            left: ((i + 1) * (100 / numVertical)) + "%"
                        }}
                    ></div>
                )}

                {/* Horizontal lines */}
                {[...Array(numHorizontal)].map((e, i) =>
                    <div
                        key={Math.random()}
                        className={styles.VHorizontalLine}
                        style={{
                            top: ((i) * (100 / (numHorizontal - 1))) + "%"
                        }}
                    ></div>
                )}

                {/* Data points */}
                {props.velocities.map((e, i) =>
                    <div
                        key={Math.random()}
                        className={styles.VDataPoint}
                        style={{
                            left: ((i + 1) * (100 / numVertical)) + "%",
                            bottom: ((e - interval.start) / interval.size) * 100 + "%",
                        }}
                    >
                        <span>{e.toFixed(2)}</span>
                    </div>

                )}

                {/* Legend */}
                {[...Array(numHorizontal)].map((e, i) =>
                    <span
                        key={Math.random()}
                        style={{
                            bottom: ((i) * (100 / (numHorizontal - 1))) + "%"
                        }}
                    >
                        {(interval.start + i * (interval.size / (numHorizontal - 1))).toFixed(2)}
                    </span>
                )}
                {[...Array(numVertical)].map((e, i) =>
                    <span
                        key={Math.random()}
                        style={{
                            marginLeft: -4,
                            top: "100%",
                            left: ((i + 1) * (100 / numVertical)) + "%"
                        }}
                    >
                        {i + 1}
                    </span>
                )}
                <span
                    style={{
                        top: "100%",
                        left: "102%"
                    }}
                >
                    REPS
                </span>

            </div>
        </div>
    );
};

type DistanceGraphProps = {
    distances: number[];
};

const DistanceGraph = (props: DistanceGraphProps) => {

    const numHorizontal = 3;
    const numVertical = Math.max(GRAPH_MIN_REPS, props.distances.length);

    const max = Math.max(...props.distances);
    const min = Math.min(...props.distances);

    const interval = generateInterval(min, max, 0.2, 2);

    return (
        <div className={styles.DistanceGraph}>
            <div className={styles.DGraphView}>
                {/* Vertical lines */}
                {[...Array(numVertical)].map((e, i) =>
                    <div
                        key={Math.random()}
                        className={styles.DVerticalLine}
                        style={{
                            left: ((i + 1) * (100 / numVertical)) + "%"
                        }}
                    ></div>
                )}

                {/* Horizontal lines */}
                {[...Array(numHorizontal)].map((e, i) =>
                    <div
                        key={Math.random()}
                        className={styles.DHorizontalLine}
                        style={{
                            top: ((i) * (100 / (numHorizontal - 1))) + "%"
                        }}
                    ></div>
                )}

                {/* Data points */}
                {props.distances.map((e, i) =>
                    <div
                        key={Math.random()}
                        className={styles.DDataPoint}
                        style={{
                            left: ((i + 1) * (100 / numVertical)) + "%",
                            height: "calc(" + ((e - interval.start) / interval.size) * 100 + "% + 7.5px)",
                        }}
                    >
                        <span>{(e * 100).toFixed()}</span>
                    </div>

                )}

                {/* Legend */}
                {[...Array(numHorizontal)].map((e, i) =>
                    <span
                        key={Math.random()}
                        style={{
                            top: ((i) * (100 / (numHorizontal - 1))) + "%",
                            marginTop: "-24px",
                        }}
                    >
                        {(interval.start + i * (interval.size / (numHorizontal - 1))).toFixed(2)}
                    </span>
                )}
                {[...Array(numVertical)].map((e, i) =>
                    <span
                        key={Math.random()}
                        style={{
                            marginLeft: -4,
                            top: "100%",
                            left: ((i + 1) * (100 / numVertical)) + "%"
                        }}
                    >
                        {i + 1}
                    </span>
                )}
                <span
                    style={{
                        top: "100%",
                        left: "102%"
                    }}
                >
                    REPS
                </span>

            </div>
        </div>
    );
};

type DetailsProps = {
    squats: Squat[];
};

function Details(props: DetailsProps)
{
    const avgV = props.squats
        .map(_ => _.velocity.mean_propulsive)
        .reduce((a, b) => a + b, 0)
        / props.squats.length;

    const avgP = props.squats
        .map(_ => _.power.peak_propulsive)
        .reduce((a, b) => a + b, 0)
        / props.squats.length;

    const avgD = props.squats
        .map(_ => _.displacement)
        .reduce((a, b) => a + b, 0)
        / props.squats.length;

    const maxV = Math.max(...props.squats.map(_ => _.velocity.mean_propulsive));
    const maxP = Math.max(...props.squats.map(_ => _.power.peak_propulsive));

    const numDuds = GRAPH_MIN_REPS - props.squats.length;

    let duds = [];

    if (numDuds >= 1)
    {
        duds = new Array(numDuds).fill(null);
    }

    let tempos = tempos_from_timings(
        props.squats.map(_ => _.timing),
        AT.forceplate.hz,
    );

    return (
        <div className={styles.Details}>
            <div className={styles.VelocityLegend}>
                Velocity
                <div />
            </div>
            <div className={styles.DetailsDataView}>
                <div>
                    <div></div>
                    <div>MPV</div>
                    <div>PPV</div>
                    {/* <div>MCV</div> */}
                    <div>Diff <sup>1</sup></div>
                    <div>Power</div>
                    <div>Distance</div>
                    <div>Tempo <sup>2</sup></div>
                </div>
                <div>
                    {props.squats.map((_, i, arr) => {

                        let change = undefined;

                        if (arr[i - 1] !== undefined)
                        {
                            change = _.velocity.mean_propulsive / arr[i - 1].velocity.mean_propulsive;
                        }

                        return (
                            <DetailLine
                                key={i}
                                rep={i + 1}
                                squat={_}
                                change={change}
                                tempo={tempos[i]}
                            />
                        );
                    })}
                    {duds.map((_, i) => (
                        <div
                            key={-i}
                            className={styles.DetailLine}
                        >
                            <div>{props.squats.length + i + 1}</div>
                            <div className="gray">—</div>
                            <div className="gray">—</div>
                            <div className="gray">—</div>
                            <div className="gray">—</div>
                            <div className="gray">—</div>
                            <div className="gray">—</div>
                        </div>
                    ))}
                </div>
            </div>
            <div className={styles.DetailsAvgView}>
                <AvgLine
                    text="Max velocity (MPV)"
                    value={maxV.toFixed(2) + " m/s"}
                />
                <AvgLine
                    text="Average velocity (MPV)"
                    value={avgV.toFixed(2) + " m/s"}
                />
                <AvgLine
                    text="Max power"
                    value={maxP.toFixed() + " w"}
                />
                <AvgLine
                    text="Average power"
                    value={avgP.toFixed() + " w"}
                />
                <AvgLine
                    text="Average distance"
                    value={(avgD * 100).toFixed() + " cm"}
                />
            </div>
        </div>
    );
}

type DetailLineProps = {
    rep: number;
    change?: number;
    squat: Squat;
    tempo: number[];
};

function DetailLine(props: DetailLineProps)
{
    const { rep, squat, change, tempo } = props;

    let changeDiv = <div className="gray">—</div>;

    if (change !== undefined)
    {
        if (change > 1)
        {
            changeDiv = (
                <div className="green">
                    {"+" + ((change - 1) * 100).toFixed() + "%"}
                </div>
            );
        }
        else if (change === 1)
        {
            changeDiv = (
                <div>
                    0%
                </div>
            );
        }
        else if (change < 1)
        {
            changeDiv = (
                <div className="red">
                    {((change - 1) * 100).toFixed() + "%"}
                </div>
            );
        }
    }

    return (
        <div className={styles.DetailLine}>
            <div>{rep}</div>
            <div>{squat.velocity.mean_propulsive.toFixed(2) + " m/s"}</div>
            <div>{squat.velocity.peak_propulsive.toFixed(2) + " m/s"}</div>
            {/* <div>{squat.velocity.mean_concentric.toFixed(2) + " m/s"}</div> */}
            {changeDiv}
            <div>{squat.power.peak_propulsive.toFixed() + " w"}</div>
            <div>{(squat.displacement * 100).toFixed() + " cm"}</div>
            <div>
                {tempo
                    .filter(_ => _ !== undefined)
                    .map((_, i) => (
                        <span key={i}>
                            {(_ * 10).toFixed()}
                        </span>
                    ))
                }
            </div>
        </div>
    )
}

type AvgLineProps = {
    text: string;
    value: string;
}

function AvgLine(props: AvgLineProps)
{
    return (
        <div className={styles.AvgLine}>
            <div>{props.text}</div>
            <div>{props.value}</div>
        </div>
    )
}

export default Results;

export {
    Results,
    Props,
};
