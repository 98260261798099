import React, { useEffect, useState } from "react";
import { PersonIcon } from "@radix-ui/react-icons";
import * as AT from "../../../init";
import styles from "./User.less";
import cn from "../../../helpers/cn";
import useSecondsDeferred from "../../../helpers/useSecondsDeferred";

const TIMEOUT = 120;

export function User() {
    const [user, setUser] = useState(AT.auth.user);
    const [active, setActive] = useState<boolean>(AT.forceplate.last_active);

    const [name, setName] = useState<string>("");
    const [signedIn, setSignedIn] = useState<boolean | undefined>();
    const [counting, setCounting] = useState<boolean>(false);

    const [seconds, start, stop] = useSecondsDeferred();

    useEffect(() => {
        AT.auth.on("user", setUser);
        return () => AT.auth.off("user", setUser);
    }, []);

    useEffect(() => {
        AT.forceplate.on("active", setActive);
        return () => AT.forceplate.off("active", setActive);
    }, []);

    useEffect(() => {
        setSignedIn(ps => {
            if (user) {
                return true;
            }

            // Prevents sign-out animation on load
            return ps === undefined
                ? undefined
                : false;
        });

        if (user) {
            setName(user.name);
        }
    }, [user]);

    useEffect(() => {
        if (!signedIn) {
            setCounting(false);
            return;
        }

        setCounting(!active);
    }, [signedIn, active]);

    useEffect(() => {
        if (counting) {
            console.log("start");
            start();
        } else {
            console.log("stop");
            stop();
        }
    }, [counting]);

    useEffect(() => {
        if (seconds >= TIMEOUT) {
            AT.auth.signOut();
        }
    }, [seconds]);

    return (
        <div className={cn(
            styles.User,
            signedIn === true && styles.SignedIn,
            signedIn === false && styles.SignedOut,
            counting && styles.Counting,
        )}>
            <div className={styles.Text}>
                <span>User </span>
                <span className={styles.Name}>{name}</span>
                <span> signed in</span>
            </div>
            <div className={styles.Icon}>
                <PersonIcon width={24} height={24} />
            </div>
            <div className={styles.Clock}>
                <span>{mss(TIMEOUT - seconds)}</span>
            </div>
            <div className={styles.OverlayWrapper}>
                <div className={styles.Overlay} />
            </div>
        </div>
    );
}

function mss(seconds: number) {
    const m = Math.max(Math.floor(seconds / 60), 0);
    const s = Math.max(seconds % 60, 0);

    return `${m}:${padStart(s.toString(), 2, "0")}`;
}

function padStart(str: string, length: number, pad: string) {
    while (str.length < length) {
        str = pad + str;
    }

    return str;
}

export default User;
