import React, { useState, useEffect } from 'react';
import styles from "./Menu.less";

type Props = {

};

const Menu = (props: Props) => {

    return (
        <div>

        </div>
    );
};

export default Menu;

export {
    Menu,
    Props,
};
