import match from "@korkje/match";
import Plausible from "plausible-tracker";

const domain = match(location.hostname)
    .on("pwr.alphatek.no", "pwr.alphatek.no")
    .on("pwr-staging.alphatek.no", "pwr-staging.alphatek.no")
    .result();

const plausible = Plausible({ domain });

export default plausible;
