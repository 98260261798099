function s_to_mss(seconds: number): string {
    const m = Math.floor(seconds / 60);
    const s = seconds % 60;

    let s_text = s < 10
        ? "0" + s
        : s.toString();

    return `${m}:${s_text}`;
}

export {
    s_to_mss,
};
