import React, { useState, useEffect } from 'react';
import { Squat } from '@alphatekas/pwr_core/dist/squatter';
import styles from "./LiveView.less";
import MidwayReport from './MidwayReport';

type Props = {
    squat: Squat | undefined;
    lastSquat: Squat | undefined;
    drop: number;
    rep: number;
    color: string;
    working: string;
    motivation: string;
};

const LiveView = (props: Props) => {

    let velocity = 0;

    if (props.squat !== undefined)
    {
        velocity = props.squat.velocity.mean_propulsive;
    }

    return (
        <div className={styles.LiveView}>
            <div className={styles.Top}>
                <div className={styles.TypeAndLoad}>
                    <div>
                        <span className={styles.Kind}>
                            Free lifting
                        </span>
                        <span className={styles.Value}>
                            Squats
                        </span>
                    </div>
                    <div>
                        <span className={styles.Kind}>
                            Reps
                        </span>
                        <span className={styles.Value}>
                            {props.rep}
                        </span>
                    </div>
                </div>
                <div className={styles.BigNumbers}>
                    <div className={styles.Number}>
                        {velocity.toFixed(2)}
                    </div>
                    <div className={styles.Indicator}>— m/s</div>
                </div>
                <div className={styles.BigNumbers}>
                    <div className={styles.Number}>
                        {props.drop}<span>%</span>
                    </div>
                    <div className={styles.Indicator}>— drop</div>
                </div>
                <div className={styles.Motivation}>
                    {props.motivation}
                </div>
            </div>
            <div className={styles.Bottom}>
                <MidwayReport
                    squat={props.squat}
                    lastSquat={props.lastSquat}
                    rep={props.rep}
                    color={props.color}
                    working={props.working}
                    drop={props.drop}
                />
            </div>
        </div>
    );
};

export default LiveView;

export {
    LiveView,
    Props,
};
