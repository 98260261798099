
 // 1/3 is added to top and bottom, so in effect 1/5 of final height
const PADDING_RELATIVE = 1 / 3;

type Interval = {
    start: number;
    stop: number;
    size: number;
};

function generateInterval(min: number, max: number, min_size: number, sections: number): Interval
{
    const variance = max - min;
    const padding = variance * PADDING_RELATIVE;

    let start = min - padding;
    let stop = max + padding;

    if ((2 * padding + variance) < min_size)
    {
        const diff = min_size - (2 * padding + variance);
        start -= diff / 2;
        stop += diff / 2;
    }

    let size = stop - start;

    // Adjust to make consistent "section" sizes (toFixed(2))
    {
        const section = size / sections;
        const rest = section - (Math.floor(section * 100) / 100);

        // Inverse to make interval greater, never smaller
        const rest_inverse_total = (0.01 - rest) * sections;

        start -= rest_inverse_total / 2;
        stop += rest_inverse_total / 2;

        // Update size
        size = stop - start;
    }

    // Also adjust to make "legends" accurate
    {
        const stop_rest = stop - (Math.floor(stop * 100) / 100);

        start -= stop_rest;
        stop -= stop_rest;
    }

    // TODO: Take radix as argument

    return {
        start,
        stop,
        size,
    };
}

export default generateInterval;

export {
    generateInterval,
    Interval,
};
