import React, { useState, useEffect } from 'react';
import QRCode from 'react-qr-code';
import User from "./User";
import logoSvg from "../../ux/svg/icons/Logo.svg";
import Burger from './Burger';
import styles from "./TopBar.less";

type Props = {};

const TopBar: React.FC<Props> = (props: Props) => {

    const clearLocalStorage = () => {
        localStorage.clear();
        location.reload();
    };

    return (
        <div className={styles.TopBar}>
            <div className={styles.LogoWrapper}>
                <div className={styles.LogoSymbol} onClick={clearLocalStorage}>
                    <img src={logoSvg}></img>
                </div>
                <div className={styles.RightWrapper}>
                    <div className={styles.LogoType}>
                        <p>Alpha<b>PWR</b></p>
                    </div>
                </div>
            </div>
            <User />
            {/* <Burger /> */}
        </div>
    );
};

export default TopBar;

export {
    TopBar,
    Props,
};
